import { BadPose, ImageQualityError, ManyObjects, Oclusion, VerificationError } from "./DefaultUserMessages";
export const defaultOperationResultMessage = {
    NONE: VerificationError,
    SUCCESS: "<h4>Verificación completada</h4> La operación se realizó con éxito. No se requieren más acciones. <br /><br />Gracias por utilizar nuestro servicio. Luego vuelva a intentar la verificación.",
    TIMEOUT: VerificationError,
    CANCELED: VerificationError,
    BAD_QUALITY: ImageQualityError,
    MATCH_NOT_FOUND: "<h4>Hubo un problema al verificar</h4> No se encontró coincidencia. <br /><br />Asegúrate de estar en un lugar bien iluminado y que tu rostro esté bien visible dentro del óvalo. <br /><br />Luego vuelva a intentar la verificación.",
    CAMERA_NOT_FOUND: VerificationError,
    FACE_NOT_FOUND: Oclusion,
    LIVENESS_CHECK_FAILED: VerificationError,
    BAD_SHARPNESS: ImageQualityError,
    TOO_NOISY: ImageQualityError,
    BAD_LIGHTNING: ImageQualityError,
    OCCLUSION: Oclusion,
    BAD_POSE: BadPose,
    TOO_MANY_OBJECTS: ManyObjects,
    MASK_DETECTED: Oclusion,
    DUPLICATE_FOUND: ManyObjects,
    DUPLICATE_ID: VerificationError,
    MOTION_BLUR: ImageQualityError,
    COMPRESSION_ARTIFACTS: VerificationError,
    TOO_DISTANT: BadPose,
    TOO_CLOSE: BadPose,
    ADJUSTING_QUALITY: VerificationError,
    INTERNAL_ERROR: VerificationError
};
