const connectionError = require("./../../images/material/connectionError.svg");
const cameraError = require("./../../images/material/cameraError.svg");
const info1 = require("./../../images/material/info1.svg");
const info2 = require("./../../images/material/info2.svg");
const manyobjects = require("./../../images/material/manyobjects.svg");
const oclusion = require("./../../images/material/oclusion.svg");
const badpose = require("./../../images/material/badpose.svg");
export const ConectionError = `
    ${connectionError}
    <h3>Hubo un problema de conexión</h3>
    <p>Asegúrate de tener una conexión estable a internet.</p>
    <p>Si el problema persiste reintenta nuevamente mas tarde.</p>
`;
export const CameraError = `
    ${cameraError}
    <h3>No pudimos activar tu cámara</h3>
    <p>Asegúrate de otorgar permisos para utilizar la cámara de tu dispositivo.</p>
    <p>Si el problema persiste es probable que otra aplicación este haciendo uso de tu cámara.</p>
`;
export const ImageQualityError = `
    ${info1}
    <h3>Mala calidad de video</h3>
    <p>Asegúrate de estar en una zona bien iluminada y vuelve a intentar</p>
    <p>Recuerda centrar tu rostro en el óvalo.</p>
`;
export const VerificationError = `
    ${info2}
    <h3>Error de verificación</h3>
    <p>Hubo un problema interno al detectar tu rostro.</p>
    <p>Vuelva a intentar.</p>
`;
export const ManyObjects = `
    ${manyobjects}
    <h3>Demasiados rostros detectados</h3>
    <p>Asegúrate de estar solo en la cámara y que tu rostro esté centrado dentro del óvalo.</p>
    <p>Luego vuelva a intentar la verificación.</p>
`;
export const Oclusion = `
    ${oclusion}
    <h3>Rostro no detectado</h3>
    <p>Asegúrate de que tu rostro esté completamente visible, libre de obstrucciones, y centrado dentro del óvalo.</p>
    <p>Luego vuelva a intentar la verificación.</p>
`;
export const BadPose = `
    ${badpose}
    <h3>Posición incorrecta</h3>
    <p>Asegúrate de que tu rostro esté centrado y mirando de frente hacia la cámara, dentro del óvalo.</p>
    <p>Luego vuelva a intentar la verificación.</p>
`;
