export var OperationResultStatus;
(function (OperationResultStatus) {
    OperationResultStatus[OperationResultStatus["NONE"] = 0] = "NONE";
    OperationResultStatus[OperationResultStatus["SUCCESS"] = 1] = "SUCCESS";
    OperationResultStatus[OperationResultStatus["TIMEOUT"] = 2] = "TIMEOUT";
    OperationResultStatus[OperationResultStatus["CANCELED"] = 3] = "CANCELED";
    OperationResultStatus[OperationResultStatus["BAD_QUALITY"] = 4] = "BAD_QUALITY";
    OperationResultStatus[OperationResultStatus["MATCH_NOT_FOUND"] = 5] = "MATCH_NOT_FOUND";
    OperationResultStatus[OperationResultStatus["CAMERA_NOT_FOUND"] = 6] = "CAMERA_NOT_FOUND";
    OperationResultStatus[OperationResultStatus["FACE_NOT_FOUND"] = 7] = "FACE_NOT_FOUND";
    OperationResultStatus[OperationResultStatus["LIVENESS_CHECK_FAILED"] = 8] = "LIVENESS_CHECK_FAILED";
    OperationResultStatus[OperationResultStatus["BAD_SHARPNESS"] = 9] = "BAD_SHARPNESS";
    OperationResultStatus[OperationResultStatus["TOO_NOISY"] = 10] = "TOO_NOISY";
    OperationResultStatus[OperationResultStatus["BAD_LIGHTNING"] = 11] = "BAD_LIGHTNING";
    OperationResultStatus[OperationResultStatus["OCCLUSION"] = 12] = "OCCLUSION";
    OperationResultStatus[OperationResultStatus["BAD_POSE"] = 13] = "BAD_POSE";
    OperationResultStatus[OperationResultStatus["TOO_MANY_OBJECTS"] = 14] = "TOO_MANY_OBJECTS";
    OperationResultStatus[OperationResultStatus["MASK_DETECTED"] = 15] = "MASK_DETECTED";
    OperationResultStatus[OperationResultStatus["DUPLICATE_FOUND"] = 16] = "DUPLICATE_FOUND";
    OperationResultStatus[OperationResultStatus["DUPLICATE_ID"] = 17] = "DUPLICATE_ID";
    OperationResultStatus[OperationResultStatus["MOTION_BLUR"] = 18] = "MOTION_BLUR";
    OperationResultStatus[OperationResultStatus["COMPRESSION_ARTIFACTS"] = 19] = "COMPRESSION_ARTIFACTS";
    OperationResultStatus[OperationResultStatus["TOO_DISTANT"] = 20] = "TOO_DISTANT";
    OperationResultStatus[OperationResultStatus["TOO_CLOSE"] = 21] = "TOO_CLOSE";
    OperationResultStatus[OperationResultStatus["ADJUSTING_QUALITY"] = 998] = "ADJUSTING_QUALITY";
    OperationResultStatus[OperationResultStatus["INTERNAL_ERROR"] = 999] = "INTERNAL_ERROR";
})(OperationResultStatus || (OperationResultStatus = {}));
;
