export const defaultIcaoWarningMessages = {
    FACE_NOT_DETECTED: "Rostro no detectado",
    ROLL_LEFT: "Girar a la izquierda",
    ROLL_RIGHT: "Girar a la derecha",
    YAW_LEFT: "Mirar a la izquierda",
    YAW_RIGHT: "Mirar a la derecha",
    PITCH_UP: "Levantar la cabeza",
    PITCH_DOWN: "Bajar la cabeza",
    TOO_NEAR: "Demasiado cerca",
    TOO_FAR: "Demasiado lejos",
    TOO_NORTH: "Demasiado hacia arriba",
    TOO_SOUTH: "Demasiado hacia abajo",
    TOO_EAST: "Demasiado hacia la derecha",
    TOO_WEST: "Demasiado hacia la izquierda",
    SHARPNESS: "Nitidez",
    BACKGROUND_UNIFORMITY: "Uniformidad del fondo",
    GRAYSCALE_DENSITY: "Densidad en escala de grises",
    SATURATION: "Saturación",
    EXPRESSION: "Expresión",
    DARK_GLASSES: "Gafas oscuras",
    EYES_CLOSED: "Ojos cerrados",
    MOUTH_OPEN: "Boca abierta",
    LOOKING_AWAY: "Mirando hacia otro lado",
    RED_EYE: "Ojo rojo",
    FACE_DARKNESS: "Oscuridad del rostro",
    UNNATURAL_SKINTONE: "Tono de piel antinatural",
    WASHED_OUT: "Desvanecido",
    PIXELATION: "Pixelación",
    SKIN_REFLECTION: "Reflejo en la piel",
    GLASSES_REFLECTION: "Reflejo en las gafas",
    HEAVY_FRAME: "Montura gruesa"
};
