/**
 * Descargo el contenido de una variable como archivo de texto
 * @param nombreArchivo Nombre del archivo
 * @param contenido Contenido de la variable a descargar
 */
export const DownloadTxt = (nombreArchivo, contenido) => {
    // Crear un Blob con el contenido del archivo
    var blob = new Blob([contenido], { type: 'text/plain' });
    // Crear un enlace para la descarga
    var enlace = document.createElement('a');
    enlace.download = nombreArchivo;
    // Crear una URL para el Blob y asignarla como href del enlace
    enlace.href = window.URL.createObjectURL(blob);
    // Simular un clic en el enlace para iniciar la descarga
    enlace.click();
    // Opcional: eliminar el enlace después de la descarga
    window.URL.revokeObjectURL(enlace.href);
    enlace.remove();
};
/**
 * Convierto cualquier dato en string
 * @param data cualquier dato
 * @returns dato transformado a string
 */
export const stringifyData = (data) => {
    // Si no hay data devuelve string vacio
    if (data === undefined || data === null)
        return '';
    // Si data es un objeto de tipo Error mapeo un objeto JSON
    // Debido a que al hacerle JSON.stringify a un objeto Error 
    // no devuelve el valor esperado.
    if (data instanceof Error)
        data = { message: data.message };
    // Verificar si es un objeto JSON
    if (typeof data === 'object') {
        try {
            // Intentar convertir a JSON
            return JSON.stringify(data);
        }
        catch (error) {
            // Si no se puede convertir, devolver el valor original
            return data.toString();
        }
    }
    else {
        // Si no es un objeto JSON, simplemente convertir a string
        return data.toString();
    }
};
/**
 * Creo un grabador de video que genera un archivo descargable cuando este hace stop
 * @param mediaStream fuente del video
 * @returns
 */
export const setupMediaRecorder = (mediaStream) => {
    // Crear un MediaRecorder
    const mediaRecorder = new MediaRecorder(mediaStream);
    // Crear una matriz para almacenar los datos del video
    let chunks = [];
    // Cuando el MediaRecorder tiene datos disponibles, los agregamos a la matriz
    mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
    };
    // Cuando la grabación se detiene, creamos un objeto Blob y generamos un enlace de descarga
    mediaRecorder.onstop = (event) => {
        const blob = new Blob(chunks, { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `video${Date.now()}.mp4`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        chunks = [];
    };
    return mediaRecorder;
};
