var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AbstractClient } from "./AbstractClient";
const defaultConfiguration = {
    baseUrl: `${location.origin}/api/session`,
};
export class BasSessionClient extends AbstractClient {
    constructor(configuration) {
        super();
        this.configuration = Object.assign(Object.assign({}, defaultConfiguration), configuration);
    }
    /**
     * Obtiene parametros iniciales para establecer conexión con el servidor de Janus
     * @returns
     */
    getInitParams() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${this.configuration.baseUrl}/initParams`;
            const initParams = yield this.get(url).then(initParams => {
                // Elimino parametros vacíos de los iceServers
                if (initParams.iceServers && initParams.iceServers.length) {
                    initParams.iceServers = initParams.iceServers.map(iceServer => {
                        const asArray = Object.entries(iceServer);
                        const filtered = asArray.filter(([key, value]) => value);
                        return Object.fromEntries(filtered);
                    });
                }
                return initParams;
            });
            return initParams;
        });
    }
    /**
     * Creo sesión de captura
     * @param transactionCode
     * @returns
     */
    createSession(transactionCode) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${this.configuration.baseUrl}/create/${transactionCode}`;
            const session = yield this.post(url);
            return session;
        });
    }
    /**
     * Establezco conexión con Janus
     * @param transactionCode Código de transacción
     * @param rtcOffer RTC Offer
     * @returns Janus Connection
     */
    janusConnect(transactionCode, rtcOffer) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${this.configuration.baseUrl}/connect/${transactionCode}`;
            const connection = yield this.post(url, rtcOffer);
            return connection;
        });
    }
}
