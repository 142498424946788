export var LogLevel;
(function (LogLevel) {
    LogLevel["Trace"] = "Trace";
    LogLevel["Debug"] = "Debug";
    LogLevel["Information"] = "Information";
    LogLevel["Warning"] = "Warning";
    LogLevel["Error"] = "Error";
    LogLevel["Critical"] = "Critical";
    LogLevel["None"] = "None";
})(LogLevel || (LogLevel = {}));
