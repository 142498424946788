var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LivenessAction } from "../models/enums/LivenessAction";
import { FaceLivennessDetection } from "./FaceLivenessDetection";
import { getCustomError, getLivenessAction, getOperationResultStatus } from '../domain/helpers/FaceVerification';
import { defaultAppConfiguration } from '../domain/defaultValues/DefaultAppConfiguration';
import { OperationResultStatus } from '../models/enums/OperationResultStatus';
import { setSvgOvalSize } from "../domain/ui/setSvgOvalSize";
import { ErrorType } from "../models/enums/ErrorType";
import { CustomErrors } from "../models/enums/CustomErrors";
export class LivenessDetection {
    constructor(configuration) {
        // public Draw: FaceLivenessDetectionCanvasDraw
        this.code = null;
        // Handlers
        this.onstart = (event) => { };
        this.onerror = (event) => { };
        this.onfinish = (event) => { };
        this.configuration = Object.assign(Object.assign(Object.assign(Object.assign({}, defaultAppConfiguration), { container: document.querySelector('[face-liveness-capturer]') }), configuration), { actionMessages: Object.assign(Object.assign({}, defaultAppConfiguration.actionMessages), configuration.actionMessages) });
        // Obtengo los elementos HTML a partir del selector contenedor
        this.getUiElements(this.configuration.container);
        // Intancio y configuro el servicio
        if (this.video) {
            this.FVO = new FaceLivennessDetection(this.video, this.configuration.FVOConfiguration);
        }
        // Reacomodo el SVG Mask cuando la ventana cambia de tamaño
        window.addEventListener("resize", () => setSvgOvalSize(this.svgMask, this.video));
        setSvgOvalSize(this.svgMask, this.video);
        setTimeout(() => {
            this.container.classList.add('ready');
        }, 500);
        this.inicializeHandlers();
    }
    // Defino los handlers de cada evento del Service de FVO
    inicializeHandlers() {
        this.FVO.onprocess = (loading) => {
            this.setLoading(loading);
        };
        this.FVO.onstart = (event) => {
            this.loading.classList.value = 'show';
            this.ovalBkg.classList.value = 'show';
            this.setMessage(getLivenessAction(LivenessAction.LOADING, this.configuration.actionMessages));
            this.onstart(event);
        };
        this.FVO.onconnected = () => {
            this.setLoading(1);
            this.loading.classList.value = 'hide';
            this.ovalMask.classList.value = 'show';
            setTimeout(() => {
                this.setMessage(getLivenessAction(LivenessAction.INIT, this.configuration.actionMessages));
            }, 500);
        };
        this.FVO.onresult = (event) => {
            setSvgOvalSize(this.svgMask, this.video, event.livenessAction);
        };
        this.FVO.onaction = (actions) => {
            this.showAction(actions[0]);
        };
        this.FVO.onfinish = (event) => {
            if (event.status === OperationResultStatus.SUCCESS
                || event.status === OperationResultStatus.MATCH_NOT_FOUND
                || event.status === OperationResultStatus.LIVENESS_CHECK_FAILED) {
                this.clearInterface();
                this.ovalBkg.classList.value = 'hide success';
                this.FVO.mediaStream.getTracks().forEach(track => track.stop());
                this.ovalBkg.onanimationend = () => {
                    window.dispatchEvent(new CustomEvent('onFinalResult', { detail: { verificationResult: true } }));
                    this.onfinish(event);
                };
            }
            else {
                this.showRetryPopUp(getOperationResultStatus(event.status));
            }
        };
        this.FVO.onerror = (error) => {
            if (error.name === ErrorType.CUSTOM_ERROR) {
                this.showRetryPopUp(getCustomError(error.type));
            }
            else {
                this.showRetryPopUp(getCustomError(CustomErrors.GENERIC_ERROR));
            }
        };
        this.FVO.onfatalerror = (error) => {
            this.onerror(error);
            this.clearInterface();
            this.ovalBkg.classList.value = 'hide error';
            this.FVO.mediaStream.getTracks().forEach(track => track.stop());
            window.dispatchEvent(new CustomEvent('onFinalResult', { detail: { verificationResult: false } }));
        };
        this.FVO.onactionlog = (event) => {
            if (this.configuration.showDebug) {
                this.showDebug(event);
            }
        };
    }
    // Comienzo la captura de rostro vivo
    startAsync(code) {
        return __awaiter(this, void 0, void 0, function* () {
            if (code) {
                this.code = code;
                return this.FVO.startAsync(code);
            }
            throw new Error('No existe el código de operación');
        });
    }
    showAction(action) {
        if (action
            && !this.blockActionMessage) {
            this.blockActionMessage = true;
            setTimeout(() => this.blockActionMessage = false, 3000);
            this.setMessage(getLivenessAction(action, this.configuration.actionMessages));
        }
    }
    setMessage(text, bkg = 'transparent', color = '#333', delay = 8000) {
        if (text
            && this.currentMessageText !== text) {
            this.hideMessage(this.currentMessageElement, () => {
                const new_message = document.createElement('div');
                new_message.setAttribute('face-liveness-message', '');
                new_message.innerHTML = text;
                new_message.className = 'show';
                this.container.appendChild(new_message);
                this.currentMessageElement = new_message;
                this.currentMessageText = text;
            });
        }
    }
    hideMessage(element = document.querySelector('[face-liveness-message].show'), callback) {
        if (element) {
            this.currentMessageElement = null;
            this.currentMessageText = '';
            element.onanimationend = (e) => {
                element.remove();
                if (callback)
                    callback();
            };
            element.className = 'hide';
        }
        else {
            if (callback)
                callback();
        }
    }
    setLoading(loading = 0.00, color = 'royalblue') {
        // Pongo un tope de 1 (100%) para el loading
        this.currentLoading = loading;
        // Actualizo los estilos del elemento HTML
        this.loading.setAttribute('value', this.currentLoading.toString());
        if (this.currentLoading < 1) {
            setTimeout(() => this.setLoading(this.currentLoading += 0.0001), 200);
        }
    }
    clearInterface() {
        // this.setLoading(0);
        if (this.ovalMask.classList.contains('show'))
            this.ovalMask.classList.value = 'hide';
        if (this.loading.classList.contains('show'))
            this.loading.classList.value = 'hide';
        if (this.retryContainer.classList.contains('show'))
            this.retryContainer.classList.value = 'hide';
        this.hideMessage();
    }
    showDebug(event) {
        const span = document.createElement('span');
        const br = document.createElement('br');
        span.innerHTML = event.action;
        span.classList.add(event.level);
        this.debugContainer.appendChild(span);
        this.debugContainer.appendChild(br);
        span.scrollIntoView();
    }
    showRetryPopUp(message, button = 'Volver a intentar') {
        this.clearInterface();
        this.ovalBkg.classList.value = 'hide';
        this.retryButton.innerHTML = button;
        this.retryButton.onclick = () => {
            this.retryContainer.className = 'hide';
            this.clearInterface();
            this.loading.classList.add('show');
            this.startAsync(this.code);
        };
        this.retryDesc.innerHTML = message;
        this.retryContainer.className = 'show';
    }
    getUiElements(container) {
        if (container) {
            this.container = container;
            this.video = this.container.querySelector('[face-liveness-video]');
            // this.loading = this.container.querySelector('[face-liveness-loading]');
            this.loading = document.createElement('md-circular-progress');
            this.svgMask = this.container.querySelector('[face-liveness-mask]');
            this.ovalMask = this.svgMask.querySelector('#oval-mask');
            this.ovalBkg = this.svgMask.querySelector('#oval-bkg');
            this.debugContainer = this.container.querySelector('[face-debug-message]');
            this.retryContainer = this.container.querySelector('[face-liveness-retry]');
            this.retryDesc = this.retryContainer.querySelector('[face-liveness-retry-desc]');
            this.retryButton = document.createElement('md-filled-button');
            this.loading.setAttribute('indeterminate', '');
            this.loading.setAttribute('face-liveness-loading', '');
            this.container.appendChild(this.loading);
            this.retryButton.setAttribute('face-liveness-retry-button', '');
            this.retryContainer.appendChild(this.retryButton);
            if (this.configuration.showDebug)
                this.debugContainer.style.display = "block";
            return true;
        }
        return false;
    }
}
