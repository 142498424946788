export var LivenessAction;
(function (LivenessAction) {
    LivenessAction[LivenessAction["KEEP_STILL"] = 1] = "KEEP_STILL";
    LivenessAction[LivenessAction["BLINK"] = 2] = "BLINK";
    LivenessAction[LivenessAction["ROTATE_YAW"] = 4] = "ROTATE_YAW";
    LivenessAction[LivenessAction["KEEP_ROTATING_YAW"] = 8] = "KEEP_ROTATING_YAW";
    LivenessAction[LivenessAction["TURN_TO_CENTER"] = 16] = "TURN_TO_CENTER";
    LivenessAction[LivenessAction["TURN_LEFT"] = 32] = "TURN_LEFT";
    LivenessAction[LivenessAction["TURN_RIGHT"] = 64] = "TURN_RIGHT";
    LivenessAction[LivenessAction["TURN_UP"] = 128] = "TURN_UP";
    LivenessAction[LivenessAction["TURN_DOWN"] = 256] = "TURN_DOWN";
    LivenessAction[LivenessAction["MOVE_CLOSER"] = 512] = "MOVE_CLOSER";
    LivenessAction[LivenessAction["MOVE_BACK"] = 1024] = "MOVE_BACK";
    // INTERNAL
    LivenessAction[LivenessAction["RETRY"] = 9999] = "RETRY";
    LivenessAction[LivenessAction["LOADING"] = 9998] = "LOADING";
    LivenessAction[LivenessAction["INIT"] = 9997] = "INIT";
})(LivenessAction || (LivenessAction = {}));
;
