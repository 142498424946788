import { defaultCustomErrorsMessage } from "./DefaultCustomErrorsMessage";
import { defaultIcaoWarningMessages } from "./DefaultIcaoWarningMessages";
import { defaultLivenessActionMessages } from "./DefaultLivenessActionMessages";
import { defaultOperationResultMessage } from "./DefaultOperationResultMessage";
export const defaultAppConfiguration = {
    container: null,
    messages: defaultCustomErrorsMessage,
    actionMessages: defaultLivenessActionMessages,
    icaoWarningMessages: defaultIcaoWarningMessages,
    resultStatusMessages: defaultOperationResultMessage,
    showDebug: false,
    maskExceptions: true,
    FVOConfiguration: null
};
