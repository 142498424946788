export var IcaoWarnings;
(function (IcaoWarnings) {
    IcaoWarnings[IcaoWarnings["FACE_NOT_DETECTED"] = 1] = "FACE_NOT_DETECTED";
    IcaoWarnings[IcaoWarnings["ROLL_LEFT"] = 2] = "ROLL_LEFT";
    IcaoWarnings[IcaoWarnings["ROLL_RIGHT"] = 4] = "ROLL_RIGHT";
    IcaoWarnings[IcaoWarnings["YAW_LEFT"] = 8] = "YAW_LEFT";
    IcaoWarnings[IcaoWarnings["YAW_RIGHT"] = 16] = "YAW_RIGHT";
    IcaoWarnings[IcaoWarnings["PITCH_UP"] = 32] = "PITCH_UP";
    IcaoWarnings[IcaoWarnings["PITCH_DOWN"] = 64] = "PITCH_DOWN";
    IcaoWarnings[IcaoWarnings["TOO_NEAR"] = 128] = "TOO_NEAR";
    IcaoWarnings[IcaoWarnings["TOO_FAR"] = 256] = "TOO_FAR";
    IcaoWarnings[IcaoWarnings["TOO_NORTH"] = 512] = "TOO_NORTH";
    IcaoWarnings[IcaoWarnings["TOO_SOUTH"] = 1024] = "TOO_SOUTH";
    IcaoWarnings[IcaoWarnings["TOO_EAST"] = 2048] = "TOO_EAST";
    IcaoWarnings[IcaoWarnings["TOO_WEST"] = 4096] = "TOO_WEST";
    IcaoWarnings[IcaoWarnings["SHARPNESS"] = 8192] = "SHARPNESS";
    IcaoWarnings[IcaoWarnings["BACKGROUND_UNIFORMITY"] = 16384] = "BACKGROUND_UNIFORMITY";
    IcaoWarnings[IcaoWarnings["GRAYSCALE_DENSITY"] = 32768] = "GRAYSCALE_DENSITY";
    IcaoWarnings[IcaoWarnings["SATURATION"] = 65536] = "SATURATION";
    IcaoWarnings[IcaoWarnings["EXPRESSION"] = 131072] = "EXPRESSION";
    IcaoWarnings[IcaoWarnings["DARK_GLASSES"] = 262144] = "DARK_GLASSES";
    IcaoWarnings[IcaoWarnings["EYES_CLOSED"] = 524288] = "EYES_CLOSED";
    IcaoWarnings[IcaoWarnings["MOUTH_OPEN"] = 1048576] = "MOUTH_OPEN";
    IcaoWarnings[IcaoWarnings["LOOKING_AWAY"] = 2097152] = "LOOKING_AWAY";
    IcaoWarnings[IcaoWarnings["RED_EYE"] = 4194304] = "RED_EYE";
    IcaoWarnings[IcaoWarnings["FACE_DARKNESS"] = 8388608] = "FACE_DARKNESS";
    IcaoWarnings[IcaoWarnings["UNNATURAL_SKINTONE"] = 16777216] = "UNNATURAL_SKINTONE";
    IcaoWarnings[IcaoWarnings["WASHED_OUT"] = 33554432] = "WASHED_OUT";
    IcaoWarnings[IcaoWarnings["PIXELATION"] = 67108864] = "PIXELATION";
    IcaoWarnings[IcaoWarnings["SKIN_REFLECTION"] = 134217728] = "SKIN_REFLECTION";
    IcaoWarnings[IcaoWarnings["GLASSES_REFLECTION"] = 268435456] = "GLASSES_REFLECTION";
    IcaoWarnings[IcaoWarnings["HEAVY_FRAME"] = 536870912] = "HEAVY_FRAME";
})(IcaoWarnings || (IcaoWarnings = {}));
;
