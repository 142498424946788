import { AbstractClient } from "./AbstractClient";
const defaultConfiguration = {
    baseUrl: `http://localhost:1880/fvo/report`,
};
export class ServerLogClient extends AbstractClient {
    constructor(configuration) {
        super();
        this.configuration = Object.assign(Object.assign({}, defaultConfiguration), configuration);
    }
    /**
     * Loguea información del proceso de captura de FVO al servidor
     * @param payload Objeto para loguear en el servidor
     */
    send(payload) {
        const url = this.configuration.baseUrl;
        this.post(url, payload);
    }
}
